<template>
  <div class="vx-card p-6">
    <base-ag-grid-header
      :rows-selected-count="rowsSelectedCountWithAllSelection"
      :filters-count="filtersAppliedCount"
      :filters-match.sync="filtersMatch"
      @reset-selection="setSelectedRowsNone()"
      @reset-filters="resetFilters()">
      <template v-slot:header-left>
        <items-per-page
          :count="count"
          :currentPage="currentPage"
          :paginationPageSize="paginationPageSize"
          @changed-page-size="paginationSetPageSize">
        </items-per-page>

        <list-action-drop-down
          v-if="isAnyRowsSelected"
          :options="defaultMultipleActionOptions"
          class="ml-3"
          @export="exportData()"
          @delete="confirmDeleteRecords()">
        </list-action-drop-down>
      </template>

      <template v-slot:header-right>
        <vs-button
          color="primary"
          type="border"
          :size="headerButtonsSizeByResolution"
          icon-pack="feather"
          icon="icon-plus"
          class="mr-3"
          @click="newItem">
          {{ $t("$General.AddNew") }}
        </vs-button>

        <vs-button
          radius
          color="primary"
          type="border"
          icon-pack="feather"
          icon="icon-refresh-cw"
          @click="resetDataSourceAndSelection()">
        </vs-button>
      </template>
    </base-ag-grid-header>

    <selection-alert-info
      v-model="selectionPageAlert"
      class="mb-5"
      :entity="entity"
      :count="count"
      :rows-selected-count="rowsSelectedCount"
      @select-all="setSelectedRowsAll()"></selection-alert-info>

    <export-alert-info
      class="mb-5"
      :entity="$tc(`$Entities.${entity}`, 2)"
      :exporting.sync="exportingList"
      :exported.sync="exportedList"
      :fileUrl="urlFileExported"
      :send-email="exportSendEmail"/>

    <ag-grid-vue
      :key="agGridKey"
      ref="agGridTable"
      :domLayout="domLayout"
      :style="gridStyle"
      :components="components"
      :frameworkComponents="frameworkComponents"
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :column-types="columnTypes"
      :getRowNodeId="getRowNodeId"
      :autoParamsRefresh="true"
      rowSelection="multiple"
      :animateRows="true"
      :suppressRowClickSelection="true"
      rowModelType="infinite"
      :pagination="hasPagination"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="suppressPaginationPanel"
      :overlayLoadingTemplate="overlayLoadingTemplate"
      :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
      :cacheBlockSize="cacheBlockSize"
      :maxBlocksInCache="maxBlocksInCache"
      @selection-changed="onSelectionChanged"
      @grid-ready="onGridReady"
      @model-updated="onModelUpdate"
      @grid-size-changed="onGridSizeChanged"
      @first-data-rendered="onFirstDataRendered"
      @row-clicked="onRowClicked"
      @row-double-clicked="onRowDoubleClicked"
      @filter-changed="onFilterChanged"
      @pagination-changed="onPaginationChanged">
    </ag-grid-vue>

    <vs-popup
      :title="titleModal"
      :active.sync="activeModalCreateOrEdit">
      <transition name="zoom-fade">
        <segment-list-create-or-edit
          v-if="showCreateOrEditComponent"
          :entity="entity"
          :operation="operation"
          :model-payload="recordSelected"
          :actions="actionsOnCreateOrEdit"
          @action="onActionFromCreateOrEdit"
          @model-was-changed="(val) => this.createOrEditModelWasChanged=val"
          @saved="savedItemOnAgGridTable"
          @close="activeModalCreateOrEdit=false"
          @redirect="redirectFromCreateOrEdit">
        </segment-list-create-or-edit>
      </transition>

    </vs-popup>

    <vs-popup
      :title="exportModalTitle"
      :active.sync="activeModalToExport">
      <transition name="zoom-fade">
        <export-json-to-excel
          v-if="showExportComponent"
          :columns="getColumnsToExport"
          :data="rowsSelected"
          @close="activeModalToExport=false"
          @export="onExport">
        </export-json-to-excel>
      </transition>
    </vs-popup>
  </div>
</template>

<script>
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import { mapActions } from 'vuex';

// Mixins
import commonListCreateOrEditWithAgGrid from '@/views/modules/_mixins/commonListCreateOrEditWithAgGrid';

// Cell Renderer
import NumberContactsCellRenderer from '@/views/modules/segment/SegmentListCellRendererContactsMatch.vue';
import CellRendererActions from '@/views/modules/_components/cell-renderer/SingleCellRendererActions.vue';

// Custom Components
import BaseAgGridHeader from '@/views/modules/_components/BaseAgGridHeader.vue';
import ItemsPerPage from '@/views/modules/_components/ItemsPerPage.vue';
import ExportJsonToExcel from '@/views/modules/_components/ExportJsonToExcel.vue';
import ListActionDropDown from '@/views/modules/_components/ListActionDropDown.vue';
import SegmentListCreateOrEdit from '@/views/modules/segment/create-or-edit/SegmentListCreateOrEdit.vue';
import AgGridSelectionHeader from '@/views/modules/_components/AgGridSelectionHeader';
import ExportAlertInfo from '@/views/modules/_components/ExportAlertInfo.vue';
import SelectionAlertInfo from '@/views/modules/SelectionAlertInfo.vue';

/**
 * Component to list and manage segments
 *
 * @module views/modules/audience/segment/SegmentList
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {string} [entity='...'] - Name of the entity associated to list
 * @vue-data {Function} exportCollectionFunction - function to export the collection
 * @vue-data {Function | null} deleteRecordFunction - function to delete one record
 * @vue-data {Function | null} deleteRecordsFunction - function to delete many records
 * @vue-data {Array.<Object>} columnDefs - Columns definition of ag grid to list
 * @vue-data {Object} components - Components to use to extend ag grid
 * @vue-data {Object} frameworkComponents - Framework components to extend ag grid
 * @vue-data {Promise<Object>} fetchAgGridData - fetch data for ag grid table
 */
export default {
  name: 'SegmentList',
  i18n: {
    messages: {
      en: {
        MatchingContacts: 'Matching contacts',
      },
    },
  },
  components: {
    BaseAgGridHeader,
    SegmentListCreateOrEdit,
    ItemsPerPage,
    ListActionDropDown,
    ExportJsonToExcel,
    ExportAlertInfo,
    SelectionAlertInfo,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererActions,
    // eslint-disable-next-line vue/no-unused-components
    NumberContactsCellRenderer,
  },
  mixins: [commonListCreateOrEditWithAgGrid],
  data() {
    return {
      entity: this.$enums.Entity.SEGMENT,
      exportCollectionFunction: this.exportSegments,
      deleteRecordFunction: this.removeSegment,
      deleteRecordsFunction: this.removeSegments,
      exportDisabled: true,
      columnDefs: [
        {
          colId: 'name',
          headerName: this.$t('$General.Name'),
          field: 'name',
          filter: true,
          minWidth: 100,
          maxWidth: 2466,
          checkboxSelection: true,
          headerComponentParams: { checkboxSelection: true },
        },
        {
          colId: 'contacts',
          headerName: this.$tc('$Entities.Contact', 2),
          headerClass: 'center-herder-ag-grid',
          field: 'filters',
          filter: false,
          minWidth: 50,
          maxWidth: 400,
          cellRendererFramework: 'NumberContactsCellRenderer',
          checkboxSelection: false,
        },
      ],
      components: {
        CellRendererActions,
        NumberContactsCellRenderer,
      },
      frameworkComponents: {
        agColumnHeader: AgGridSelectionHeader,
      },
    };
  },
  methods: {
    ...mapActions({
      fetchAllSegments: 'segment/fetchAllSegments',
      exportSegments: 'segment/exportFile',
      removeSegment: 'segment/removeSegment',
      removeSegments: 'segment/removeSegments',
    }),
    async fetchAgGridData(params) {
      return this.fetchAllSegments(params);
    },
  },
};

</script>

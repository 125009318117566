<template>
  <div>
    <contacts-match-count
      :filters-match="filtersMatch"
      :get-valid-filters="() => filters"
      :is-there-any-valid-filter="true"
      :show-refresh="false"
      :selected-from-campaign="false"
      :manage-contacts-links="false"
      :show-contacts-list-link="true"
      :count-message="$tc('ContactsCountText', countContacts, {
              count: countContacts,
            })"
      :hide-count="true"
      contacts-list-link-classes="link-plain"
      @count-changed="countContacts = $event"
    />
  </div>
</template>

<script>
import enums from '@/enums';
import ContactsMatchCount
from '@/views/modules/segment/create-or-edit/SegmentListCreateOrEditContactsMatchCount.vue';

/**
 * Show numbers of contacts that match with segment filters
 *
 * @module views/modules/segment/SegmentListCellRendererContactsMatch
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {number} [countContacts=0] - number of contacts that match with segment filters
 * @vue-computed {Object} filters - filters from segment
 * @vue-computed {string} filtersMatch - filters match from segment
 * @vue-event {void} getCountContacts - get count of contacts that match with segment filters
 */
export default {
  name: 'NumberContactsCellRenderer',
  i18n: {
    messages: {
      en: {
        ContactsCountText: '{count} contacts match | {count} contact match | {count} contacts match',
      },
    },
  },
  components: { ContactsMatchCount },
  data() {
    return {
      countContacts: 0,
    };
  },
  computed: {
    filters() {
      return this.params
        ? this.params.value
        : {};
    },
    filtersMatch() {
      return this.params && this.params.data
        ? this.params.data.filtersMatch
        : enums.AppFilterMathType.ALL;
    },
  },
};
</script>
